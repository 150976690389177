<template>
  <div>

    <v-dialog v-model="dialog.detail" fullscreen scrollable hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark max-height="70" color="#d31145">
          <v-toolbar-title :class="$vuetify.breakpoint.name === 'xs' ? 'body-2' : ''">Detail Transfer Stok
            <span class="font-weight-bold">({{ detail.code }})</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark fab @click="dialog.detail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4" >
          <v-row>
            <v-col cols="12" md="12" :class="$vuetify.breakpoint.name === 'xs' ? 'pt-1' : ''">
              <v-card flat>
                <div class="font-weight-regular text-second"
                  :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 mb-1' : 'text-h6 mb-5'">
                  Informasi Transfer
                </div>
                <v-simple-table class="px-3">
                  <template>
                    <tbody>
                      <tr>
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">Kode Tranfer</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ detail.code }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">Status</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          <div :class="
                            detail.status == 'diterima'
                              ? 'caption font-weight-bold text-right teal--text'
                              : 'caption font-weight-bold text-right red--text text--darken-2'
                          ">
                            {{ detail.status == 'diproses' ? 'Menunggu Konfirmasi' : 'Diterima' }}
                          </div>
                        </td>
                      </tr>
                      <tr v-if="detail.receive_user_id !== '0'">
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">Diterima Oleh</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ detail.receive_user_name }}
                        </td>
                      </tr>
                      <tr v-if="detail.receive_user_id !== '0'">
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">Diterima Pada</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ detail.receive_datetime }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" :class="$vuetify.breakpoint.name === 'xs' ? 'pt-1' : ''">
              <v-card flat>
                <div class="font-weight-regular text-second"
                  :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 mb-1' : 'text-h6 mb-5'">
                  Outlet Asal
                </div>
                <v-simple-table class="px-3">
                  <template>
                    <tbody>
                      <tr>
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">Nama Outlet</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ detail.origin_store_json_object !== undefined ? detail.origin_store_json_object.name : '' }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">Alamat</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ detail.origin_store_json_object !== undefined ? detail.origin_store_json_object.address : '' }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">Nama Pemilik (Merchant)</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ detail.origin_store_json_object !== undefined ? detail.origin_store_json_object.owner_name : '' }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card flat>
                <div class="font-weight-regular text-second"
                  :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 mb-1' : 'text-h6 mb-5'">
                  Outlet Tujuan
                </div>
                <v-simple-table class="px-3">
                  <template>
                    <tbody>
                      <tr>
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">Nama Outlet</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ detail.destination_store_json_object !== undefined ? detail.destination_store_json_object.name : '' }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">Alamat</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ detail.destination_store_json_object !== undefined ? detail.destination_store_json_object.address : '' }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" :width="$vuetify.breakpoint.name === 'xs' ? '30%' : '30%'">Nama Pemilik (Merchant)</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right text-second">
                          {{ detail.destination_store_json_object !== undefined ? detail.destination_store_json_object.owner_name : '' }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
    
          <div class="font-weight-regular mt-7 text-second"
            :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 mb-0' : 'text-h6 mb-5'">
            Item Produk
          </div>
          <v-data-table flat class="laporan" :class="$vuetify.breakpoint.name === 'xs' ? 'mt-0' : 'mt-5'"
            :headers="headersDetail" hide-default-footer disable-pagination
            :items="detail.product_json_array">
            <template v-slot:[`item.origin`]="{ item }">
              <v-list-item class="pl-0">
                <v-list-item-content>
                  <v-list-item-title v-html="item.origin.name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.origin.category_name" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:[`item.destination`]="{ item }">
              <v-list-item class="pl-0">
                <v-list-item-content>
                  <v-list-item-title v-html="item.destination.name" class="subtitle-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.destination.category_name" class="caption"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:[`item.qty`]="{ item }">
              {{ item.qty }} {{ item.origin.unit }}
            </template>
            <template v-slot:[`item.note`]="{ item }">
              {{ item.note == '' ? '-' : item.note  }}
            </template>
            <template v-slot:[`item.receive.qty`]="{ item }">
              {{ item.receive.qty }} {{ item.origin.unit }}
            </template>
            <template v-slot:[`item.receive.note`]="{ item }">
              {{ item.receive.note == '' ? '-' : item.receive.note }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG DELETE ==== -->
    <v-dialog v-model="dialog.delete" persistent max-width="450">
      <v-card>
        <v-card-title class="title">Peringatan !</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5 text-center body-2">
          Apakah anda yakin ingin menghapus transfer stok '{{ detail.code }}' ?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="pb-0 px-0">
          <v-alert type="error" text dense prominent v-show="response.length > 0" v-html="response">
          </v-alert>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn small width="70" color="#d31145" elevation="0" :loading="process.form" :disabled="process.form"
            class="white--text text-capitalize" @click="deletes">
            Yakin
          </v-btn>
          <v-btn small width="70" elevation="0" class="text-capitalize" :disabled="process.form"
            @click="dialog.delete = false; detail = {}">
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG FORM ==== -->
    <v-dialog v-model="dialog.form" transition="dialog-bottom-transition" scrollable fullscreen>
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          Tambah Transfer Stok
          <v-spacer></v-spacer>
          <v-btn icon @click="
          dialog.form = false;
          $refs['validation-new-invitation'].reset();">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
  
        <v-divider></v-divider>
    
        <v-card-text class="pa-4">
          <ValidationObserver ref="validation-new-invitation">
            <v-form @:keyup.enter="save()">
              <ValidationProvider ref="form-origin_store_json_object" name="Pilih Outlet Asal" rules="required" v-slot="{ errors }">
                <v-autocomplete class="required select-table" outlined dense attach persistent-hint :items="arrStore" return-object item-text="store_name"
                  color="#d31145" label="Outlet Asal" v-on:change="changeOrigin" v-model="form.origin_store_json_object" :error-messages="errors" required>
                  <template v-slot:selection="data">
                    <v-list-item-content class="w-100" v-bind="data.attrs" :input-value="data.selected">
                      <v-list-item-title class="subtitle-2">{{ data.item.store_name }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{ data.item.owner_name }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2">{{ data.item.store_name }}</v-list-item-title>
                        <v-list-item-subtitle class="caption">{{ data.item.owner_name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </ValidationProvider>
              <ValidationProvider ref="form-destination_store_json_object" name="Pilih Outlet Tujuan" rules="required" v-slot="{ errors }">
                <v-autocomplete class="required select-table" outlined dense attach persistent-hint :items="arrStoreDest" return-object item-text="store_name"
                  color="#d31145" label="Outlet Tujuan" v-on:change="changeDestination" v-model="form.destination_store_json_object" :error-messages="errors" required>
                  <template v-slot:selection="data">
                    <v-list-item-content class="w-100" v-bind="data.attrs" :input-value="data.selected">
                      <v-list-item-title class="subtitle-2">{{ data.item.store_name }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{ data.item.owner_name }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2">{{ data.item.store_name }}</v-list-item-title>
                        <v-list-item-subtitle class="caption">{{ data.item.owner_name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </ValidationProvider>
              <div v-if="form.destination_store_json_object.store_id !== undefined && form.origin_store_json_object.store_id !== undefined">
                <ValidationProvider ref="form-product_json_array" name="Pilih produk" rules="required"
                v-slot="{ errors }">
                  <v-btn small elevation="0" color="#d31145" class="white--text text-capitalize" :disabled="process.form" :error-messages="errors"
                    @click="addProduct">
                    Tambah Produk
                  </v-btn>
                </ValidationProvider>
                <div>
                  <v-row class="justify-space-between align-center" v-for="(item, index) in form.product_json_array" :key="index">
                    <v-col cols="3">
                      <ValidationProvider :ref="`form-product_origin${index}`" name="Pilih Produk Tujuan" rules="required" v-slot="{ errors }">
                        <v-autocomplete class="required mt-4 mb-0" outlined attach dense persistent-hint :items="arrProduct" return-object
                          item-text="name" color="#d31145" label="Produk Asal" v-on:change="changeDestinationProduct(index)"
                          v-model="item.origin" :error-messages="errors" required>
                          <template v-slot:selection="data">
                            <v-list-item-content class="w-100" v-bind="data.attrs" :input-value="data.selected">
                              <v-list-item-title class="subtitle-2">{{ data.item.name }}</v-list-item-title>
                              <v-list-item-subtitle class="caption">Stok: {{ data.item.stock_balance }} {{ data.item.unit
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                          <template v-slot:item="data">
                            <template>
                              <v-list-item-content>
                                <v-list-item-title class="subtitle-2">{{ data.item.name }}</v-list-item-title>
                                <v-list-item-subtitle class="caption">Stok: {{ data.item.stock_balance }} {{ data.item.unit
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="3">
                      <ValidationProvider :ref="`form-product_destination${index}`" name="Pilih Produk Tujuan" rules="required" v-slot="{ errors }">
                        <v-autocomplete class="required mt-4 mb-0" outlined dense attach chips persistent-hint :items="item.productDest" return-object
                          item-text="name" color="#d31145" label="Produk Tujuan" v-model="item.destination" :error-messages="errors" required>
                          <template v-slot:selection="data">
                            <v-list-item-content class="w-100" v-bind="data.attrs" :input-value="data.selected">
                              <v-list-item-title class="subtitle-2">{{ data.item.name }}</v-list-item-title>
                              <v-list-item-subtitle class="caption">Stok: {{ data.item.stock_balance }} {{ data.item.unit
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                          <template v-slot:item="data">
                            <template>
                              <v-list-item-content>
                                <v-list-item-title class="subtitle-2">{{ data.item.name }}</v-list-item-title>
                                <v-list-item-subtitle class="caption">Stok: {{ data.item.stock_balance }} {{ data.item.unit
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="2">
                      <ValidationProvider name="Jumlah" :ref="`form-jumlah${index}`"
                        :rules="`${item.origin.stock_balance !== 0 ? 'required|min_1:1|max_value:' + item.origin.stock_balance : 'required'}`"
                        v-slot="{ errors }">
                        <v-currency-field class="required mt-4 mb-0" label="Jumlah" outlined dense id="price" color="#d31145" v-model="item.qty"
                          :error-messages="errors" required />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="3">
                      <v-textarea class="mt-4 mb-0" outlined dense rows="1" color="#d31145" label="Catatan" placeholder="Tuliskan catatan" v-model="item.note">
                      </v-textarea>
                    </v-col>
                    <v-col cols="1">
                      <v-btn small text min-width="30" :disabled="process.form" class="text-capitalize red--text pa-0 mr-1"
                        @click="removeProduct(index)">
                        <v-icon small>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-divider></v-divider>
    
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#d31145" depressed class="white--text text-none" :disabled="process.run" :loading="process.run"
            @click="save()">
            <v-icon left>mdi-check-circle</v-icon>
            Simpan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="justify-space-between">
      <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 6 : 6">
        <div class="text--secondary mb-2" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 font-weight-bold' : 'title'">
          Data Transfer Stok
        </div>
        <div class="d-flex">
          <v-btn depressed :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true" :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
            elevation="0" color="#d31145" :disabled="process.run" @click="actions(null, 'add')"
            class="white--text text-capitalize mb-3 mr-2 rounded-lg">
            <span v-if="$vuetify.breakpoint.name !== 'xs'">Tambah</span>
            <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-plus</v-icon>
          </v-btn>
          <Export class="mb-3 mr-2" :file_type="'xls'" :btnText="'Export Excel'" :btnIcon="'mdi-microsoft-excel'"
            :btnColor="'teal'" :url="`franchise/stock/transfer/export_excel?store_id=${selected_store_id}`" :title="'Data Transfer Stok'"
            ref="base-export" v-on:onClickExport="responseExport" />
        </div>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 6 : 4">
        <v-text-field outlined dense :hide-details="true" v-model="searching" placeholder="Cari Data . . ." clearable
          color="#d31145" @click:clear="
            searching = '';
            fetch();
            showPage = false;
          " v-on:keyup.enter="
            fetch();
            showPage = false;
          " prepend-inner-icon="mdi-magnify">
        </v-text-field>
      </v-col>
    </v-row>

    <!-- ==== LIST DATA MONITORING STOK ==== -->
    <div class="my-5"
      :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'min-height: calc(100vh - 485px)'">
      <v-skeleton-loader :loading="process.run" transition="scale-transition" :tile="false" type="table-tbody">
        <!-- ====BASE TABLE==== -->
        <base-table
          :customHeight="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'calc(100vh - 460px)'"
          v-show="displayBaseTable" :isExternalFilterButton="true" :isFilterOnHeader="true" ref="base-table"
          :filterHeader="filterHeader_stock" itemKey="stock_id" :single-select="singleSelect" :headers="headers"
          :retrieveDataURL="`franchise/stock/transfer?store_id=${selected_store_id}`" v-on:onRetrieveDataEnd="responseDataEnd">
          <!-- ?filter[5][type]=string&filter[5][field]=product_is_have_variant&filter[5][comparison]===&filter[5][value]=Tidak -->
          <template v-slot:[`item.aksi_table`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text min-width="30"
                  class="text-capitalize orange--text text--darken-1 pa-0 mr-1" @click="toDetail(item)">
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </template>
              <span>Detail Transfer Stok</span>
            </v-tooltip>
            <v-tooltip v-if="item.status == 'diproses'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text min-width="30" color="red" class="text-capitalize pa-0 mr-1"
                  @click="actions(item, 'delete')">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Hapus Transfer Stok</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.origin_store_json_object`]="{ item }">
            <v-list-item class="pl-0">
              <v-list-item-content>
                <v-list-item-title v-html="item.origin_store_json_object.name" class="subtitle-2"></v-list-item-title>
                <v-list-item-subtitle v-html="item.origin_store_json_object.owner_name" class="caption"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:[`item.destination_store_json_object`]="{ item }">
            <v-list-item class="pl-0">
              <v-list-item-content>
                <v-list-item-title v-html="item.destination_store_json_object.name" class="subtitle-2"></v-list-item-title>
                <v-list-item-subtitle v-html="item.destination_store_json_object.owner_name" class="caption"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div :class="
              item.status == 'diterima'
                ? 'caption font-weight-bold text-center teal--text'
                : 'caption font-weight-bold text-center red--text text--darken-2'
            ">
              {{ item.status == 'diproses' ? 'Menunggu Konfirmasi' : 'Diterima' }}
            </div>
          </template>
    
          <template v-slot:filterTitle>
            Filter Data Transfer Stok
          </template>
        </base-table>
      </v-skeleton-loader>
      <!-- ====END BASE TABLE ==== -->
    
      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-model="contentEmpty" v-show="emptyData" />
    </div>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show">
      <div>{{ snackbar.text }}</div>
    </v-snackbar>

  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import Pagination from "@/components/Pagination";
import Export from "@/components/Export";
import Empty from "@/components/Empty";

export default {
  data() {
    return{
      displayBaseTable: false,
      emptyData: false,
      arr_categoryProduct: [],
      singleSelect: true,
      searching: "",
      response: "",
      contentEmpty: "",
      headers: [
        {
          text: "Aksi",
          sortable: false,
          width: "10",
          value: "aksi_table",
          align: "left",
        },
        {
          text: "Kode Transaksi",
          sortable: true,
          width: "150",
          value: "code",
          align: "start",
        },
        {
          text: "Outlet Asal",
          width: "100",
          align: "start",
          sortable: true,
          value: "origin_store_json_object",
        },
        {
          text: "Outlet Tujuan",
          width: "100",
          align: "start",
          sortable: true,
          value: "destination_store_json_object",
        },

        {
          text: "Status",
          width: "100",
          sortable: true,
          value: "status",
          align: "center",
        },
      ],
      headersDetail: [
        {
          text: "Produk Asal",
          width: "100",
          align: "start",
          sortable: true,
          value: "origin",
        },
        {
          text: "Produk Tujuan",
          width: "100",
          align: "start",
          sortable: true,
          value: "destination",
        },
        {
          text: "Jumlah Transfer",
          width: "100",
          align: "start",
          sortable: true,
          value: "qty",
        },

        {
          text: "Catatan",
          width: "100",
          sortable: true,
          value: "note",
          align: "start",
        },
        {
          text: "Jumlah Diterima",
          width: "100",
          align: "start",
          sortable: true,
          value: "receive.qty",
        },

        {
          text: "Catatan Penerimaan",
          width: "100",
          sortable: true,
          value: "receive.note",
          align: "start",
        },
      ],
      pagination: {
        data: {},
        page: "",
      },
      limit: 10,
      page: 1,
      selected: {
        pagination: 0,
        menu: 0,
      },
      form: {
        origin_store_json_object: {},
        destination_store_json_object: {},
        product_json_array: []
      },
      arrStore:[],
      arrStoreDest:[],
      arrStoreDest:[],
      arrProduct:[],
      arrProductDest:[],
      detail:{
        // origin_store_json_object: {},
        // destination_store_json_object: {},
      },
      process: {
        form: false,
        run: false,
      },
      snackbar: {},
      dialog: {
        delete: false,
        ImagePreview: false,
        snackbar: false,
        detail: false,
        form: false,
      },
    }
  },
  components: {
    Export,
    Empty,
    Pagination
  },
  watch: {
    searching: _.debounce(function (newVal) {
      this.searching = newVal;
      this.pagination.page = newVal === null ? "" : 1;
      // this.fetch();
      this.$refs["base-table"].searchVal = newVal;
      this.$refs["base-table"].retrieveData();
    }, 500),
    // "selected.pagination": function(val) {
    //   this.fetch(val + 1);
    // },
    "selected.menu": function (val) {
      this.selected.menu = val === undefined ? 0 : this.selected.menu;

      // this.fetch();
    },
  },
  created() {

  },
  computed: {
    user() {
      return JSON.parse(TokenService.getUser());
    },
    selected_store_id() {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },
    filterHeader_stock() {
      return [
        {
          type: "text",
          column: "name",
          valueDefault: "",
          label: "Berdasarkan Nama Produk",
        },
        {
          type: "autocomplete",
          column: "category_name",
          valueDefault: "",
          items: this.arr_categoryProduct,
          label: "Berdasarkan Kategori",
        },
        {
          type: "opsi",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "select",
          column: "is_active_bool",
          valueDefault: "",
          label: "Pilih Status",
          items: ["Aktif", "Tidak Aktif"],
        },
        {
          type: "opsi",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "text",
          column: "stock_balance",
          valueDefault: "",
          label: "Berdasarkan Stok Sekarang",
        },
      ];
    },
  },
  mounted(){

  },
  methods: {
    responseExport() {
      //send params from base table to base export
      this.$refs["base-export"].params = this.$refs["base-table"].params;

      // KALAU MAU PAKE SEARCH
      this.$refs["base-export"].params.search = this.$refs[
        "base-table"
      ].searchVal;

      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;
    },
    /**
     * HANDLE RESPONSE FROM COMPONENT DATATABLE
     */
    responseDataEnd(res) {
      this.loading = false;
      this.process.run = false;
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data belum tersedia atau data yang Anda cari tidak tersedia";
    },
    /**
     * HANDLE RESPONSE FROM COMPONENT DATATABLE
     */
    async toDetail(item) {
      // this.detail = item;
      // this.dialog.detail = true;
      await get(`franchise/stock/transfer/detail`, {
        params: {
          // store_id: this.selected_store_id,
          id: item.id
        }
      }).then(response => {
        let res = response.data;
        if (res.status == 200) {
          this.detail = res.results.data;
          this.dialog.detail = true;
        } else {
          this.detail = {
            // origin_store_json_object: {},
            // destination_store_json_object: {},
          };
          this.dialog.detail = false;
        }
      })
    },

    async changeOrigin(){
      this.form.destination_store_json_object = {}
      this.form.product_json_array = []
      this.arrStoreDest = this.arrStore.filter( item => item.store_id !== this.form.origin_store_json_object.store_id)
      await get(`franchise/store/product_product_variant`, {
        params: {
          store_id: this.form.origin_store_json_object.store_id,
          is_show_stock_bool: true,
          'stock_balance[gt]': 0,
          pagination_bool: false,
        }
      }).then(response => {
        let res = response.data;
        if (res.status == 200) {
          this.arrProduct = res.results.data;
          this.dialog.form = true
        } else {

        }
      })
    },

    async changeDestination() {
      if(this.form.destination_store_json_object.store_id !== undefined){
        await get(`franchise/store/product_product_variant`, {
          params: {
            store_id: this.form.destination_store_json_object.store_id,
            is_show_stock_bool: true,
            pagination_bool: false
          }
        }).then(response => {
          let res = response.data;
          if (res.status == 200) {
            this.arrProductDest = res.results.data;
            this.dialog.form = true
            for(let i = 0; i < this.form.product_json_array.length; i++){
              this.form.product_json_array[i].productDest = []
              this.form.product_json_array[i].destination = {}
            }
          } else {

          }
        })
      }
    },

    changeDestinationProduct(index){
      this.form.product_json_array[index].productDest = this.arrProductDest.filter(item => item.unit === this.form.product_json_array[index].origin.unit)
    },

    onChangeQty(index){
      if(this.form.product_json_array[index].qty > this.form.product_json_array[index].origin.qty){
        this.form.product_json_array[index].qty = this.form.product_json_array[index].origin.qty
      }
    },

    addProduct(){
      this.form.product_json_array.push({
        "origin": {
          qty: 0
        },
        "productDest":[],
        "destination": {},
        "qty": "0",
        "note": ""
      })
    },

    removeProduct(index){
      this.form.product_json_array.splice(index,1)
    },

    async actions(item, type) {
      switch (type) {
        case 'add':
          await get(`franchise/store`, {
            params: {
              pagination_bool: false
            }
          }).then(response => {
            let res = response.data;
            if (res.status == 200) {
              this.form = {
                origin_store_json_object: {},
                destination_store_json_object: {},
                product_json_array: []
              }
              this.arrStore = res.results.data;
              this.arrStoreDest = res.results.data;
              this.dialog.form = true
            } else {
              
            }
          })
          break
        case 'delete':
          this.detail = item
          this.dialog.delete = true
          break
      }
    },

    async deletes() {
      this.process.form = true;
      this.responseSuccess = "";
      await post(`franchise/stock/transfer/delete`, {
        data: {
          id: this.detail.id,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status == 200) {
            this.dialog.delete = false;
            this.process.form = false;
            this.form.id = ""

            this.snackbar.show = true;
            this.snackbar.color = "success";
            this.snackbar.text = res.message;

            this.$refs["base-table"].retrieveData();
          } else {
            this.process.form = false;

            this.snackbar.show = true;
            this.snackbar.color = "error";
            this.snackbar.text = res.message;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },

    async save() {
      let _self = this;

      this.feedback = "";

      let isValid = true;

      if(this.form.destination_store_json_object.id === undefined){
        this.$refs[`form-destination_store_json_object`].applyResult({
          errors: ['Kolom tidak boleh kosong'],
          valid: false,
          failedRules: {},
        });
        isValid = false
      }
      if(this.form.origin_store_json_object.id === undefined){
        this.$refs[`form-origin_store_json_object`].applyResult({
          errors: ['Kolom tidak boleh kosong'],
          valid: false,
          failedRules: {},
        });
        isValid = false
      }
      if(this.form.product_json_array.length <= 0){
        this.$refs[`form-product_json_array`].applyResult({
          errors: ['Kolom tidak boleh kosong'],
          valid: false,
          failedRules: {},
        });
        isValid = false
      }

      let arrProduct = this.form.product_json_array
      for (let i = 0; i < arrProduct.length; i++){
        if(arrProduct[i].destination.id === undefined){
          this.$refs[`form-product_destination${i}`][0].applyResult({
            errors: ['Kolom tidak boleh kosong'],
            valid: false,
            failedRules: {},
          });
          isValid = false
        }
        if(arrProduct[i].origin.id === undefined){
          this.$refs[`form-product_origin${i}`][0].applyResult({
            errors: ['Kolom tidak boleh kosong'],
            valid: false,
            failedRules: {},
          });
          isValid = false
        }
        if(arrProduct[i].qty < 1){
          this.$refs[`form-jumlah${i}`][0].applyResult({
            errors: ['Kolom minimal 1'],
            valid: false,
            failedRules: {},
          });
          isValid = false
        }else{
          if (arrProduct[i].qty > arrProduct[i].origin.stock_balance) {
            this.$refs[`form-jumlah${i}`][0].applyResult({
              errors: ['Kolom maksimal ' + arrProduct[i].origin.stock_balance],
              valid: false,
              failedRules: {},
            });
            isValid = false
          }
        }
      }

      if (isValid) {
        this.process.run = true;
        let url = 'franchise/stock/transfer'
        let data = {...this.form}
        data.destination_store_json_object.id = data.destination_store_json_object.store_id
        data.origin_store_json_object.id = data.origin_store_json_object.store_id
        
        await post(url, {
          data
        })
          .then((response) => {
            let res = response.data
            if (res.status === 200) {
              this.dialog.form = false;
              this.$refs["validation-new-invitation"].reset();

              this.form = {
                origin_store_json_object: {},
                destination_store_json_object: {},
                product_json_array: []
              }

              this.process.run = false;
              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.snackbar.text = res.message;

              this.$refs["base-table"].retrieveData();
            } else {
              if (
                res.error_code &&
                res.error_code == "error_validation"
              ) {
                this.feedback = "Cek kembali form Anda!";

                this.process.run = false

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }

              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = "Permintaan gagal!";
              }
              this.process.run = false
            }
          })
          .catch(function (error) {
            // console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
            this.process.run = false;
          });
        this.process.run = false;
      }
    },

  }
}
</script>

<style>
.select-table{
  overflow: visible;
}
/* .v-select__selections{
  display: flex;
  flex-direction: column;
  flex:none;
}
.v-select.v-text-field input{
  flex: none;
  padding: 0px;
} */
/* .table-body{
  position: relative;
}
.table-tr{
  position: absolute;
} */
</style>